var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-col',{staticClass:"p-0",attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',[_vm._v(_vm._s(_vm.$t("Modules.User.Label.History Send Email")))])],1),_c('b-card-body',[_c('b-card-text',[_c('b-form-group',{attrs:{"label-for":"customerData"}},[_c('v-select',{attrs:{"id":"customerData","options":_vm.customerDataOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":_vm.$t('Modules.User.Label.Customers')},model:{value:(_vm.customerChoosed),callback:function ($$v) {_vm.customerChoosed=$$v},expression:"customerChoosed"}})],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',[_vm._v(_vm._s(_vm.$t("Modules.User.Label.Customer Information")))])],1),_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{staticClass:"col-lg-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Modules.User.Label.Staff'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Staff'),"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.userOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":_vm.$t('Modules.User.Label.Staff')},model:{value:(_vm.emailData.user),callback:function ($$v) {_vm.$set(_vm.emailData, "user", $$v)},expression:"emailData.user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"col-lg-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Modules.User.Label.Customer Prefix'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Customer Prefix'),"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.customerPrefix[_vm.$i18n.locale],"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":_vm.$t('Modules.User.Label.Customer Prefix')},model:{value:(_vm.emailData.customer.prefix),callback:function ($$v) {_vm.$set(_vm.emailData.customer, "prefix", $$v)},expression:"emailData.customer.prefix"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"col-lg-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Modules.User.Label.Customer Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Customer Name'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Modules.User.Label.Customer Name')},model:{value:(_vm.emailData.customer.name),callback:function ($$v) {_vm.$set(_vm.emailData.customer, "name", $$v)},expression:"emailData.customer.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"col-lg-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Modules.User.Label.Customer Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Customer Email'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Modules.User.Label.Customer Email')},model:{value:(_vm.emailData.customer.email),callback:function ($$v) {_vm.$set(_vm.emailData.customer, "email", $$v)},expression:"emailData.customer.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},_vm._l((_vm.emailData.plans),function(plan,planIndex){return _c('b-card',{key:plan.key,attrs:{"no-body":""}},[(planIndex == 0)?_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',[_vm._v(_vm._s(plan.name ? plan.name : ". . ."))]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PlusIcon","size":"18"},on:{"click":function($event){$event.preventDefault();return _vm.addPlan($event)}}})],1):_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',[_vm._v(_vm._s(plan.name ? plan.name : ". . ."))]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"18"},on:{"click":function($event){$event.preventDefault();return _vm.removePlan(planIndex)}}})],1),_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{staticClass:"col-lg-4",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Insured'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Modules.User.Label.Insured')},model:{value:(plan.name),callback:function ($$v) {_vm.$set(plan, "name", $$v)},expression:"plan.name"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"col-lg-4",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Gender'),"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.genderOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":_vm.$t('Modules.User.Label.Gender')},model:{value:(plan.prefix),callback:function ($$v) {_vm.$set(plan, "prefix", $$v)},expression:"plan.prefix"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"col-lg-4",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Rate Class'),"state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"select-transparent select-small-text",staticStyle:{"margin-top":"-2px"},attrs:{"options":_vm.rateClasses,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":_vm.$t('Modules.User.Label.Rate Class')},model:{value:(plan.rateClass),callback:function ($$v) {_vm.$set(plan, "rateClass", $$v)},expression:"plan.rateClass"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"col-lg-4",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Age'),"state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"select-transparent",staticStyle:{"margin-top":"-2px"},attrs:{"options":_vm.ages,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":_vm.$t('Modules.User.Label.Age')},on:{"input":function($event){return _vm.chooseAge(plan)}},model:{value:(plan.age),callback:function ($$v) {_vm.$set(plan, "age", $$v)},expression:"plan.age"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"col-lg-4",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Dob'),"state":errors.length > 0 ? false : null}},[_c('flat-pickr',{staticClass:"form-control input-transparent",staticStyle:{"margin-top":"-2px"},attrs:{"config":{
                                  dateFormat: 'm/d/Y',
                                },"placeholder":_vm.$t('Modules.User.Label.Dob')},on:{"input":function($event){return _vm.chooseDob(plan)}},model:{value:(plan.dob),callback:function ($$v) {_vm.$set(plan, "dob", $$v)},expression:"plan.dob"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"col-lg-4",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Program'),"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.programOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":_vm.$t('Modules.User.Label.Program')},on:{"input":function($event){return _vm.calculateGuaranteedAge(plan)}},model:{value:(plan.program),callback:function ($$v) {_vm.$set(plan, "program", $$v)},expression:"plan.program"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},_vm._l((plan.programs),function(program,programIndex){return _c('b-card',{key:program.key,staticClass:"shadow-none plan-program",attrs:{"border-variant":"success","bg-variant":"transparent"}},[(programIndex == 0)?_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',[(program.faceAmount)?_c('span',[_vm._v(_vm._s(plan.program ? plan.program.value + " - " : "")+_vm._s(_vm._f("currency")(program.faceAmount)))]):_c('span',[_vm._v(". . .")])]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PlusIcon","size":"18"},on:{"click":function($event){$event.preventDefault();return _vm.addPlanProgram(plan.programs)}}})],1):_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',[(program.faceAmount)?_c('span',[_vm._v(_vm._s(plan.program ? plan.program.value + " - " : "")+_vm._s(_vm._f("currency")(program.faceAmount)))]):_c('span',[_vm._v(". . .")])]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"18"},on:{"click":function($event){$event.preventDefault();return _vm.removePlanProgram(
                                    plan.programs,
                                    programIndex
                                  )}}})],1),_c('b-card-body',{class:plan.program != null &&
                                plan.program.value == 'Index Universal Life'
                                  ? 'pad-bot-0'
                                  : ''},[_c('b-card-text',[_c('b-row',[_c('b-col',{staticClass:"col-lg-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Face Amount'),"state":errors.length > 0 ? false : null}},[_c('currency-input',{attrs:{"options":{
                                            currency: 'USD',
                                            precision: 2,
                                          },"errors":errors},model:{value:(program.faceAmount),callback:function ($$v) {_vm.$set(program, "faceAmount", $$v)},expression:"program.faceAmount"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"col-lg-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t(
                                            'Modules.User.Label.Monthly Premium'
                                          ),"state":errors.length > 0 ? false : null}},[_c('currency-input',{attrs:{"options":{
                                            currency: 'USD',
                                            precision: 2,
                                          },"errors":errors},model:{value:(program.monthlyPremium),callback:function ($$v) {_vm.$set(program, "monthlyPremium", $$v)},expression:"program.monthlyPremium"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"col-lg-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Year to Pay'),"state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"select-transparent",staticStyle:{"margin-top":"-2px"},attrs:{"options":_vm.yearToPays,"selectable":function (option) { return !option.value.includes(
                                                'select_value'
                                              ); },"label":"text","placeholder":_vm.$t(
                                              'Modules.User.Label.Year to Pay'
                                            )},on:{"input":function($event){return _vm.calculateGuaranteedAge(plan)}},model:{value:(program.yearToPay),callback:function ($$v) {_vm.$set(program, "yearToPay", $$v)},expression:"program.yearToPay"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"col-lg-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t(
                                            'Modules.User.Label.Guaranteed Age'
                                          ),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t(
                                              'Modules.User.Label.Guaranteed Age'
                                            ),"readonly":""},model:{value:(program.guaranteedAge),callback:function ($$v) {_vm.$set(program, "guaranteedAge", $$v)},expression:"program.guaranteedAge"}})],1)]}}],null,true)})],1)],1)],1)],1),(
                                plan.program != null &&
                                  plan.program.value == 'Index Universal Life'
                              )?_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t("Modules.User.Label.Cash Value"))+" ")]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PlusIcon","size":"18"},on:{"click":function($event){$event.preventDefault();return _vm.addPlanProgramCashValue(program)}}})],1):_vm._e(),(
                                plan.program != null &&
                                  plan.program.value == 'Index Universal Life'
                              )?_c('b-card-body',[_c('b-card-text',[_c('b-row',_vm._l((program.cashValues),function(cashValue,cashValueIndex){return _c('b-col',{key:cashValueIndex,staticClass:"col-lg-6",attrs:{"cols":"12"}},[_c('b-card',{staticClass:"shadow-none plan-program",attrs:{"border-variant":"success","bg-variant":"transparent"}},[_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title'),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"18"},on:{"click":function($event){$event.preventDefault();return _vm.removePlanProgramCashValue(
                                              program.cashValues,
                                              cashValueIndex
                                            )}}})],1),_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.At the age'),"state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"select-transparent",staticStyle:{"margin-top":"-2px"},attrs:{"options":_vm.ages,"selectable":function (option) { return !option.value.includes(
                                                          'select_value'
                                                        ); },"label":"text","placeholder":_vm.$t(
                                                        'Modules.User.Label.Age'
                                                      )},model:{value:(cashValue.age),callback:function ($$v) {_vm.$set(cashValue, "age", $$v)},expression:"cashValue.age"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modules.User.Label.Cash Value'),"state":errors.length > 0 ? false : null}},[_c('currency-input',{attrs:{"options":{
                                                      currency: 'USD',
                                                      precision: 2,
                                                    },"errors":errors},model:{value:(cashValue.value),callback:function ($$v) {_vm.$set(cashValue, "value", $$v)},expression:"cashValue.value"}})],1)]}}],null,true)})],1)],1)],1)],1)],1)],1)}),1)],1)],1):_vm._e()],1)}),1)],1)],1)],1)],1)}),1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end mar-bot-21",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"gradient-primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.sendEmail($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SendIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("Modules.User.Label.Send Email")))])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }