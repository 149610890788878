<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-form cols="12">
          <validation-observer ref="simpleRules">
            <b-col lg="12" class="p-0">
              <b-row>
                <b-col cols="12">
                  <b-card no-body>
                    <b-card-header class="pb-1">
                      <b-card-title>{{
                        $t("Modules.User.Label.History Send Email")
                      }}</b-card-title>
                    </b-card-header>
                    <b-card-body>
                      <b-card-text>
                        <b-form-group label-for="customerData">
                          <v-select
                            id="customerData"
                            v-model="customerChoosed"
                            :options="customerDataOptions"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                            :placeholder="$t('Modules.User.Label.Customers')"
                          />
                        </b-form-group>
                      </b-card-text>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>

              <!-- Customer Information - Begin -->
              <b-row>
                <b-col cols="12">
                  <b-card no-body>
                    <b-card-header class="pb-1">
                      <b-card-title>{{
                        $t("Modules.User.Label.Customer Information")
                      }}</b-card-title>
                    </b-card-header>
                    <b-card-body>
                      <b-card-text>
                        <b-row>
                          <b-col cols="12" class="col-lg-3">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Modules.User.Label.Staff')"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Staff')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <v-select
                                  v-model="emailData.user"
                                  :options="userOptions"
                                  :selectable="
                                    (option) =>
                                      !option.value.includes('select_value')
                                  "
                                  label="text"
                                  :placeholder="$t('Modules.User.Label.Staff')"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" class="col-lg-3">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Modules.User.Label.Customer Prefix')"
                              rules="required"
                            >
                              <b-form-group
                                :label="
                                  $t('Modules.User.Label.Customer Prefix')
                                "
                                :state="errors.length > 0 ? false : null"
                              >
                                <v-select
                                  v-model="emailData.customer.prefix"
                                  :options="customerPrefix[$i18n.locale]"
                                  :selectable="
                                    (option) =>
                                      !option.value.includes('select_value')
                                  "
                                  label="text"
                                  :placeholder="
                                    $t('Modules.User.Label.Customer Prefix')
                                  "
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" class="col-lg-3">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Modules.User.Label.Customer Name')"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Customer Name')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <b-form-input
                                  v-model="emailData.customer.name"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t('Modules.User.Label.Customer Name')
                                  "
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" class="col-lg-3">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Modules.User.Label.Customer Email')"
                              rules="required|email"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Customer Email')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <b-form-input
                                  v-model="emailData.customer.email"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t('Modules.User.Label.Customer Email')
                                  "
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
              <!-- Customer Information - End -->
              <!-- Plans - Begin -->
              <b-row>
                <b-col cols="12">
                  <b-card
                    no-body
                    v-for="(plan, planIndex) in emailData.plans"
                    :key="plan.key"
                  >
                    <b-card-header class="pb-1" v-if="planIndex == 0">
                      <b-card-title>{{
                        plan.name ? plan.name : ". . ."
                      }}</b-card-title>
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                        class="cursor-pointer"
                        @click.prevent="addPlan"
                      />
                    </b-card-header>
                    <b-card-header class="pb-1" v-else>
                      <b-card-title>{{
                        plan.name ? plan.name : ". . ."
                      }}</b-card-title>
                      <feather-icon
                        icon="XIcon"
                        size="18"
                        class="cursor-pointer"
                        @click.prevent="removePlan(planIndex)"
                      />
                    </b-card-header>
                    <b-card-body>
                      <b-card-text>
                        <b-row>
                          <b-col cols="12" class="col-lg-4">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Insured')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <b-form-input
                                  v-model="plan.name"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t('Modules.User.Label.Insured')
                                  "
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" class="col-lg-4">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Gender')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <v-select
                                  v-model="plan.prefix"
                                  :options="genderOptions"
                                  :selectable="
                                    (option) =>
                                      !option.value.includes('select_value')
                                  "
                                  label="text"
                                  :placeholder="$t('Modules.User.Label.Gender')"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" class="col-lg-4">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Rate Class')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <v-select
                                  v-model="plan.rateClass"
                                  :options="rateClasses"
                                  :selectable="
                                    (option) =>
                                      !option.value.includes('select_value')
                                  "
                                  label="text"
                                  :placeholder="
                                    $t('Modules.User.Label.Rate Class')
                                  "
                                  class="select-transparent select-small-text"
                                  style="margin-top: -2px;"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" class="col-lg-4">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Age')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <v-select
                                  v-model="plan.age"
                                  :options="ages"
                                  :selectable="
                                    (option) =>
                                      !option.value.includes('select_value')
                                  "
                                  label="text"
                                  :placeholder="$t('Modules.User.Label.Age')"
                                  class="select-transparent"
                                  style="margin-top: -2px;"
                                  @input="chooseAge(plan)"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" class="col-lg-4">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Dob')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <flat-pickr
                                  v-model="plan.dob"
                                  class="form-control input-transparent"
                                  :config="{
                                    dateFormat: 'm/d/Y',
                                  }"
                                  style="margin-top: -2px;"
                                  :placeholder="$t('Modules.User.Label.Dob')"
                                  @input="chooseDob(plan)"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" class="col-lg-4">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('Modules.User.Label.Program')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <v-select
                                  v-model="plan.program"
                                  :options="programOptions"
                                  :selectable="
                                    (option) =>
                                      !option.value.includes('select_value')
                                  "
                                  label="text"
                                  :placeholder="
                                    $t('Modules.User.Label.Program')
                                  "
                                  @input="calculateGuaranteedAge(plan)"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <b-card
                              border-variant="success"
                              bg-variant="transparent"
                              class="shadow-none plan-program"
                              v-for="(program, programIndex) in plan.programs"
                              :key="program.key"
                            >
                              <b-card-header
                                class="pb-1"
                                v-if="programIndex == 0"
                              >
                                <b-card-title>
                                  <span v-if="program.faceAmount"
                                    >{{
                                      plan.program
                                        ? plan.program.value + " - "
                                        : ""
                                    }}{{ program.faceAmount | currency }}</span
                                  >
                                  <span v-else>. . .</span>
                                </b-card-title>
                                <feather-icon
                                  icon="PlusIcon"
                                  size="18"
                                  class="cursor-pointer"
                                  @click.prevent="addPlanProgram(plan.programs)"
                                />
                              </b-card-header>
                              <b-card-header class="pb-1" v-else>
                                <b-card-title>
                                  <span v-if="program.faceAmount"
                                    >{{
                                      plan.program
                                        ? plan.program.value + " - "
                                        : ""
                                    }}{{ program.faceAmount | currency }}</span
                                  >
                                  <span v-else>. . .</span>
                                </b-card-title>
                                <feather-icon
                                  icon="XIcon"
                                  size="18"
                                  class="cursor-pointer"
                                  @click.prevent="
                                    removePlanProgram(
                                      plan.programs,
                                      programIndex
                                    )
                                  "
                                />
                              </b-card-header>
                              <b-card-body
                                :class="
                                  plan.program != null &&
                                  plan.program.value == 'Index Universal Life'
                                    ? 'pad-bot-0'
                                    : ''
                                "
                              >
                                <b-card-text>
                                  <b-row>
                                    <b-col cols="12" class="col-lg-3">
                                      <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="
                                            $t('Modules.User.Label.Face Amount')
                                          "
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <currency-input
                                            v-model="program.faceAmount"
                                            :options="{
                                              currency: 'USD',
                                              precision: 2,
                                            }"
                                            :errors="errors"
                                          />
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col cols="12" class="col-lg-3">
                                      <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="
                                            $t(
                                              'Modules.User.Label.Monthly Premium'
                                            )
                                          "
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <currency-input
                                            v-model="program.monthlyPremium"
                                            :options="{
                                              currency: 'USD',
                                              precision: 2,
                                            }"
                                            :errors="errors"
                                          />
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col cols="12" class="col-lg-3">
                                      <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="
                                            $t('Modules.User.Label.Year to Pay')
                                          "
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <v-select
                                            v-model="program.yearToPay"
                                            :options="yearToPays"
                                            :selectable="
                                              (option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                            "
                                            label="text"
                                            :placeholder="
                                              $t(
                                                'Modules.User.Label.Year to Pay'
                                              )
                                            "
                                            class="select-transparent"
                                            style="margin-top: -2px;"
                                            @input="
                                              calculateGuaranteedAge(plan)
                                            "
                                          />
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col cols="12" class="col-lg-3">
                                      <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="
                                            $t(
                                              'Modules.User.Label.Guaranteed Age'
                                            )
                                          "
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <b-form-input
                                            v-model="program.guaranteedAge"
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                            :placeholder="
                                              $t(
                                                'Modules.User.Label.Guaranteed Age'
                                              )
                                            "
                                            readonly
                                          />
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                  </b-row>
                                </b-card-text>
                              </b-card-body>

                              <b-card-header
                                class="pb-1"
                                v-if="
                                  plan.program != null &&
                                    plan.program.value == 'Index Universal Life'
                                "
                              >
                                <b-card-title>
                                  {{ $t("Modules.User.Label.Cash Value") }}
                                </b-card-title>
                                <feather-icon
                                  icon="PlusIcon"
                                  size="18"
                                  class="cursor-pointer"
                                  @click.prevent="
                                    addPlanProgramCashValue(program)
                                  "
                                />
                              </b-card-header>
                              <b-card-body v-if="
                                  plan.program != null &&
                                    plan.program.value == 'Index Universal Life'
                                ">
                                <b-card-text>
                                  <b-row>
                                    <b-col cols="12" class="col-lg-6"
                                      v-for="(cashValue, cashValueIndex) in program.cashValues"
                                      :key="cashValueIndex"
                                    >
                                      <b-card
                                        border-variant="success"
                                        bg-variant="transparent"
                                        class="shadow-none plan-program"
                                      >
                                        <b-card-header class="pb-1">
                                          <b-card-title></b-card-title>
                                          <feather-icon
                                            icon="XIcon"
                                            size="18"
                                            class="cursor-pointer"
                                            @click.prevent="
                                              removePlanProgramCashValue(
                                                program.cashValues,
                                                cashValueIndex
                                              )
                                            "
                                          />
                                        </b-card-header>
                                        <b-card-body>
                                          <b-card-text>
                                            <b-row>
                                              <b-col cols="6">
                                                <validation-provider
                                                  #default="{ errors }"
                                                  rules="required"
                                                >
                                                  <b-form-group
                                                    :label="
                                                      $t('Modules.User.Label.At the age')
                                                    "
                                                    :state="
                                                      errors.length > 0 ? false : null
                                                    "
                                                  >
                                                    <v-select
                                                      v-model="cashValue.age"
                                                      :options="ages"
                                                      :selectable="
                                                        (option) =>
                                                          !option.value.includes(
                                                            'select_value'
                                                          )
                                                      "
                                                      label="text"
                                                      :placeholder="
                                                        $t(
                                                          'Modules.User.Label.Age'
                                                        )
                                                      "
                                                      class="select-transparent"
                                                      style="margin-top: -2px;"
                                                    />
                                                  </b-form-group>
                                                </validation-provider>
                                              </b-col>
                                              <b-col cols="6">
                                                <validation-provider
                                                  #default="{ errors }"
                                                  rules="required"
                                                >
                                                  <b-form-group
                                                    :label="
                                                      $t('Modules.User.Label.Cash Value')
                                                    "
                                                    :state="
                                                      errors.length > 0 ? false : null
                                                    "
                                                  >
                                                    <currency-input
                                                      v-model="cashValue.value"
                                                      :options="{
                                                        currency: 'USD',
                                                        precision: 2,
                                                      }"
                                                      :errors="errors"
                                                    />
                                                  </b-form-group>
                                                </validation-provider>
                                              </b-col>
                                            </b-row>
                                          </b-card-text>
                                        </b-card-body>
                                      </b-card>
                                    </b-col>
                                  </b-row>
                                </b-card-text>
                              </b-card-body>
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-end mar-bot-21">
                  <b-button
                    variant="gradient-primary"
                    type="submit"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    @click.prevent="sendEmail"
                  >
                    <feather-icon icon="SendIcon" class="mr-50" />
                    <span class="align-middle">{{
                      $t("Modules.User.Label.Send Email")
                    }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <!-- Plans - End -->
            </b-col>
            <!-- /Input Data -->
          </validation-observer>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormDatalist,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CurrencyInput from "@core/components/custom/CurrencyInput";
import SendEmailRepository from "@/modules/sendemail/repository/sendEmailRepository";
import AuthRepository from "@/modules/auth/repository/authRepository";
import { required, email } from "@validations";

function Program() {
  this.key = "program_" + new Date().getTime();
  this.faceAmount = null;
  this.monthlyPremium = null;
  this.yearToPay = null;
  this.guaranteedAge = null;
  this.cashValues = [];
}

function Plan() {
  this.key = "plan_" + new Date().getTime();
  this.name = null;
  this.prefix = null;
  this.age = null;
  this.dob = null;
  this.rateClass = null;
  this.program = null;
  this.programs = [new Program()];
}

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BFormInvalidFeedback,
    BFormValidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    CurrencyInput,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userOptions: [],
      users: [],
      customerPrefix: {
        vi: [
          {
            text: "Anh",
            value: "anh",
          },
          {
            text: "Chị",
            value: "chị",
          },
          {
            text: "Cô",
            value: "cô",
          },
          {
            text: "Chú",
            value: "chú",
          },
          {
            text: "Ông",
            value: "ông",
          },
          {
            text: "Bà",
            value: "bà",
          },
        ],
        en: [
          {
            text: "Mr",
            value: "Mr",
          },
          {
            text: "Mrs.",
            value: "Mrs",
          },
          {
            text: "Miss",
            value: "Miss",
          },
          {
            text: "Ms",
            value: "Ms",
          },
        ],
      },
      ages: [],
      yearToPays: [],
      rateClasses: [
        {
          text: "Elite Non-Tobacco",
          value: "Elite Non-Tobacco",
        },
        {
          text: "Preferred Non-Tobacco",
          value: "Preferred Non-Tobacco",
        },
        {
          text: "Select Non-Tobacco",
          value: "Select Non-Tobacco",
        },
        {
          text: "Standard Non-Tobacco",
          value: "Standard Non-Tobacco",
        },
        {
          text: "Express Standard Non-Tobacco 1",
          value: "Express Standard Non-Tobacco 1",
        },
        {
          text: "Express Standard Non-Tobacco 2",
          value: "Express Standard Non-Tobacco 2",
        },
        {
          text: "Preferred Tobacco",
          value: "Preferred Tobacco",
        },
        {
          text: "Standard Tobacco",
          value: "Standard Tobacco",
        },
        {
          text: "Express Standard Tobacco",
          value: "Express Standard Tobacco",
        },
        {
          text: "Uninsurable",
          value: "Uninsurable",
        },
      ],
      emailData: {
        user: null,
        customer: {
          prefix: null,
          name: null,
          email: null,
        },
        plans: [new Plan()],
      },
      customerDataOptions: [],
      customerData: [],
      customerChoosed: null,
      programOptions: [
        {
          text: "IUL",
          value: "Index Universal Life",
        },
        {
          text: "Term Life",
          value: "Term Life",
        },
      ],
      genderOptions: [
        {
          text: this.$i18n.t("Modules.User.Label.Man"),
          value: "Man",
        },
        {
          text: this.$i18n.t("Modules.User.Label.Woman"),
          value: "Woman",
        },
      ],
      required,
      email,
    };
  },
  computed: {},
  methods: {
    sendEmail() {
      const vm = this;
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          vm.showLoading();
          SendEmailRepository.sendEmail(vm.emailData).then((response) => {
            vm.hideLoading();
            vm.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: vm.$i18n.t("Success"),
                icon: "CheckIcon",
                variant: "success",
                text: vm.$i18n.t("Modules.User.Message.Sent successfully"),
              },
            });
            vm.emailData.time = Date.now();
            vm.saveData(vm.emailData);
            vm.customerChoosed = null;
            vm.emailData = {
              user: null,
              customer: {
                prefix: null,
                name: null,
                email: null,
              },
              plans: [new Plan()],
            };
            vm.$refs.simpleRules.reset();
          });
        } else {
          vm.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: vm.$i18n.t("Warning"),
              icon: "AlertTriangleIcon",
              variant: "warning",
              text: vm.$i18n.t(
                "Modules.User.Message.Please fill in the required information"
              ),
            },
          });
        }
      });
    },
    addPlan() {
      const vm = this;
      vm.emailData.plans.push(new Plan());
    },
    removePlan(planIndex) {
      const vm = this;
      vm.$bvModal
        .msgBoxConfirm(
          vm.$i18n.t("message.Are you sure you want to delete this item?"),
          {
            okVariant: "gradient-danger",
            cancelVariant: "outline-secondary",
            okTitle: vm.$i18n.t("Confirm"),
            cancelTitle: vm.$i18n.t("Cancel"),
          }
        )
        .then((value) => {
          if (value) {
            if (vm.emailData.plans.length == 1) {
              vm.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: vm.$i18n.t("Warning"),
                  icon: "AlertTriangleIcon",
                  variant: "warning",
                  text: vm.$i18n.t(
                    "Modules.User.Message.Should have at least one plan"
                  ),
                },
              });
            } else {
              vm.emailData.plans.splice(planIndex, 1);
              vm.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: vm.$i18n.t("Success"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: vm.$i18n.t("message.Deleted successfully"),
                },
              });
            }
          }
        });
    },
    addPlanProgram(planPrograms) {
      planPrograms.push(new Program());
    },
    removePlanProgram(planPrograms, planProgramIndex) {
      const vm = this;
      vm.$bvModal
        .msgBoxConfirm(
          vm.$i18n.t("message.Are you sure you want to delete this item?"),
          {
            okVariant: "gradient-danger",
            cancelVariant: "outline-secondary",
            okTitle: vm.$i18n.t("Confirm"),
            cancelTitle: vm.$i18n.t("Cancel"),
          }
        )
        .then((value) => {
          if (value) {
            if (planPrograms.length == 1) {
              vm.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: vm.$i18n.t("Warning"),
                  icon: "AlertTriangleIcon",
                  variant: "warning",
                  text: vm.$i18n.t(
                    "Modules.User.Message.Should have at least one plan"
                  ),
                },
              });
            } else {
              planPrograms.splice(planProgramIndex, 1);
              vm.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: vm.$i18n.t("Success"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: vm.$i18n.t("message.Deleted successfully"),
                },
              });
            }
          }
        });
    },
    addPlanProgramCashValue(program) {
      program.cashValues.push({age: null, value: null});
    },
    removePlanProgramCashValue(planProgramCashValues, planProgramCashValueIndex) {
      const vm = this;
      vm.$bvModal
        .msgBoxConfirm(
          vm.$i18n.t("message.Are you sure you want to delete this item?"),
          {
            okVariant: "gradient-danger",
            cancelVariant: "outline-secondary",
            okTitle: vm.$i18n.t("Confirm"),
            cancelTitle: vm.$i18n.t("Cancel"),
          }
        )
        .then((value) => {
          if (value) {
            planProgramCashValues.splice(planProgramCashValueIndex, 1);
            vm.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: vm.$i18n.t("Success"),
                icon: "CheckIcon",
                variant: "success",
                text: vm.$i18n.t("message.Deleted successfully"),
              },
            });
          }
        });
    },
    chooseAge(plan) {
      const vm = this;
      if (plan.age != null) {
        plan.dob = vm.convertAgeToDob(plan.dob, plan.age.value);
        vm.calculateGuaranteedAge(plan);
      }
    },
    chooseDob(plan) {
      const vm = this;
      if (plan.dob != null) {
        var age = vm.convertDobToAge(plan.dob);
        if (age == 0) {
          plan.age = null;
        } else {
          plan.age = {
            text:
              age +
              " " +
              vm.$i18n.t("Modules.User.Label.Years Old").toLowerCase(),
            value: age.toString(),
          };
        }
        vm.calculateGuaranteedAge(plan);
      }
    },
    convertDobToAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age <= 0) {
        age = 0;
      }
      return age;
    },
    convertAgeToDob(dob, age) {
      var today = new Date();
      var birthYear = today.getFullYear() - age;
      if (dob == null) {
        return "01/01/" + birthYear;
      }
      var birthDate = new Date(dob);
      return (
        (birthDate.getMonth() + 1 > 9
          ? birthDate.getMonth() + 1
          : "0" + (birthDate.getMonth() + 1)) +
        "/" +
        (birthDate.getDate() > 9
          ? birthDate.getDate()
          : "0" + birthDate.getDate()) +
        "/" +
        birthYear
      );
    },
    calculateGuaranteedAge(plan) {
      const vm = this;
      if (plan.program != null) {
        plan.programs.forEach((program, programIndex) => {
          if (plan.program.value == "Index Universal Life") {
            program.guaranteedAge =
              "120 " + vm.$i18n.t("Modules.User.Label.Years Old").toLowerCase();
          } else {
            if (plan.age != null && program.yearToPay != null) {
              program.guaranteedAge =
                parseInt(plan.age.value) +
                parseInt(program.yearToPay.value) +
                " " +
                vm.$i18n.t("Modules.User.Label.Years Old").toLowerCase();
            }
          }
        });
      }
    },
    saveData(newData) {
      const vm = this;
      let plans = [];
      if (localStorage.getItem("customerData")) {
        plans = JSON.parse(localStorage.getItem("customerData"));
      }
      plans.unshift(newData);
      localStorage.setItem("customerData", JSON.stringify(plans));
      vm.customerData = plans;
      vm.loadCustomerToSelect();
    },
    loadCustomerData() {
      const vm = this;
      if (vm.customerChoosed) {
        vm.emailData = vm.customerData[vm.customerChoosed.value];
      }
    },
    loadCustomerToSelect() {
      const vm = this;
      if (vm.customerData) {
        vm.customerDataOptions = [];
        for (const [key, value] of Object.entries(vm.customerData)) {
          vm.customerDataOptions.push({
            text:
              value.customer.name +
              " - " +
              vm.formatDateMMDDYYYYHH(new Date(value.time)),
            value: key,
          });
        }
      }
    },
    checkAuth() {
      const vm = this;
      vm.showLoading();
      AuthRepository.checkAuth().then((response) => {});
    },
  },
  created() {
    const vm = this;
    vm.checkAuth();

    vm.userOptions = [];
    vm.users = JSON.parse(localStorage.getItem("users"));
    for (const [key, value] of Object.entries(vm.users)) {
      vm.userOptions.push({
        text: value.name,
        value: key,
      });
    }
    vm.ages = [];
    for (let i = 1; i <= 120; i++) {
      vm.ages.push({
        text:
          i.toString() +
          " " +
          vm.$i18n.t("Modules.User.Label.Years Old").toLowerCase(),
        value: i.toString(),
      });
    }
    vm.yearToPays = [];
    for (let i = 2; i <= 120; i++) {
      vm.yearToPays.push({
        text: i + " " + vm.$i18n.t("Modules.User.Label.Years").toLowerCase(),
        value: i.toString(),
      });
    }
    vm.customerDataOptions = [];
    vm.customerData = JSON.parse(localStorage.getItem("customerData"));
    vm.loadCustomerToSelect();
  },
  watch: {
    $currentLocale(locale) {
      const vm = this;
      vm.emailData.customer.prefix = null;
      localize(locale);
      vm.$refs.simpleRules.reset();

      vm.genderOptions = [
        {
          text: this.$i18n.t("Modules.User.Label.Man"),
          value: "Man",
        },
        {
          text: this.$i18n.t("Modules.User.Label.Woman"),
          value: "Woman",
        },
      ];

      vm.ages = [];
      for (let i = 1; i <= 120; i++) {
        vm.ages.push({
          text:
            i.toString() +
            " " +
            vm.$i18n.t("Modules.User.Label.Years Old").toLowerCase(),
          value: i.toString(),
        });
      }
      vm.yearToPays = [];
      for (let i = 2; i <= 120; i++) {
        vm.yearToPays.push({
          text: i + " " + vm.$i18n.t("Modules.User.Label.Years").toLowerCase(),
          value: i.toString(),
        });
      }
      vm.emailData.plans.forEach((plan, index) => {
        if (plan.prefix != null) {
          plan.prefix = {
            text: vm.$i18n.t("Modules.User.Label." + plan.prefix.value),
            value: plan.prefix.value,
          };
        }
        if (plan.age != null) {
          plan.age = {
            text:
              plan.age.value +
              " " +
              vm.$i18n.t("Modules.User.Label.Years Old").toLowerCase(),
            value: plan.age.value,
          };
        }
        plan.programs.forEach((program, programIndex) => {
          if (program.yearToPay != null) {
            program.yearToPay = {
              text:
                program.yearToPay.value +
                " " +
                vm.$i18n.t("Modules.User.Label.Year").toLowerCase(),
              value: program.yearToPay.value,
            };
          }
          program.cashValues.forEach((cashValue, cashValueIndex) => {
            if(cashValue.age != null) {
              cashValue.age = {
              text:
                cashValue.age.value +
                " " +
                vm.$i18n.t("Modules.User.Label.Years Old").toLowerCase(),
              value: cashValue.age.value,
            };
            }
          });
        });
      });
    },
    customerChoosed() {
      const vm = this;
      vm.loadCustomerData();
    },
  },
};
</script>
<style lang="scss">
.form-group.is-invalid .flatpickr-input {
  border-color: #ea5455 !important;
}
.plan-program {
  > .card-body {
    padding: 0;
  }
}
</style>
