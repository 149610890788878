<template>
  <span>
    <b-form-input
      ref="inputRef"
      placeholder="$100,000.00"
      class="input-transparent"
      type="text"
      :value="formattedValue"
      :state="typeof errors != 'undefined' && errors.length > 0 ? false : null"
    />
  </span>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import {
  BFormInput,
} from "bootstrap-vue";

export default {
  name: "CurrencyInput",
  props: {
    value: Number,
    options: Object,
    errors: Array
  },
  components: {
    BFormInput,
  },
  setup(props) {
    const { formattedValue, inputRef } = useCurrencyInput(props.options);
    return { inputRef, formattedValue };
  },
};
</script>
